<h1 mat-dialog-title>{{ 'dashboard.import_json' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <sz-text-box
        [formControl]="dashboardJson"
        [required]="true"
        placeholder="dashboard.json"
    />
    <sz-dialog-button-bar
        primaryText="btn.create"
        [primaryDisabled]="!isValidJson || dashboardJson.hasError('required')"
        (primaryClick)="handleImportJson()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
